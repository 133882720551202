import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#383838',
        },
        secondary: {
            main: '#757575',
        },
    },
    typography: {
        fontFamily: 'Merriweather',
        h1: {
            fontSize: '2.3rem',
            fontWeight: 'bold'
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 'bold'
        },
        h3: {
            fontSize: '1.4rem',
            fontWeight: 'bold'
        },
        h4: {
            fontSize: '1.3rem',
            fontWeight: 'bold'
        },
        h5: {
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        h6: {
            fontSize: '1.1rem',
            fontWeight: 'bold'
        },
        caption: {
            fontSize: '0.8rem'
        },
        body1: {
            fontSize: '1rem'
        }
    },
});

export default theme;
