'use client'

import React, { useState } from "react";
import { Navbar, NavbarBrand, NavbarMenuToggle, NavbarMenuItem, NavbarMenu, NavbarContent, NavbarItem, Link, Dropdown, DropdownMenu, DropdownItem, DropdownTrigger, Button } from "@nextui-org/react";
import { Typography } from "@mui/material";
import Image from "next/image";

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  return (
    <Navbar className="bg-primary-light text-neutral-dark navbar" isBlurred isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} maxWidth="2xl" style={{ zIndex: '1001' }}>
      <NavbarBrand>
        <Link href="/" className="font-bold text-inherit" size="lg">
          <Image src="/android-chrome-192x192.png" height={40} width={40} alt="Logo" />
          Hebamme Lea Stiffler
        </Link>
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>
          <Link href="/" size="lg" className="font-bold text-neutral-dark">
            Home
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Dropdown showArrow>
            <DropdownTrigger>
              <Button
                className="font-bold">
                Angebot
              </Button>
            </DropdownTrigger>
            <DropdownMenu className="bg-primary-light-secondary dropdown-navigation custom-dropdown-menu" aria-label="Angebot">
              <DropdownItem>
                <Typography>
                  <Link color="primary" href="/schwangerschaftsbetreuung" className="font-bold block w-full text-neutral-dark" size="lg">
                    Schwangerschaftsbetreuung
                  </Link>
                </Typography>
              </DropdownItem>
              <DropdownItem>
                <Typography>
                  <Link href="/geburtsvorbereitungskurs" className="font-bold block w-full text-neutral-dark" size="lg">
                    Geburtsvorbereitungskurs
                  </Link>
                </Typography>
              </DropdownItem>
              <DropdownItem>
                <Typography>
                  <Link color="primary" href="/akupunktur" className="font-bold block w-full text-neutral-dark" size="lg">
                    Akupunktur
                  </Link>
                </Typography>
              </DropdownItem>
              <DropdownItem>
                <Typography>
                  <Link color="primary" href="/taping" className="font-bold block w-full text-neutral-dark" size="lg">
                    Taping
                  </Link>
                </Typography>
              </DropdownItem>
              <DropdownItem>
                <Typography>
                  <Link color="primary" href="/wochenbettbetreuung" className="font-bold block w-full text-neutral-dark" text-neutral-dark size="lg">
                    Wochenbettbetreuung
                  </Link>
                </Typography>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarItem>
        <NavbarItem>
          <Link href="/beruf" className="font-bold text-neutral-dark" size="lg">
            Beruf
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link href="/faq" className="font-bold text-neutral-dark" size="lg">
            FAQ
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link href="/kontakt" className="font-bold text-neutral-dark" size="lg">
            Kontakt
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent className="hidden sm:flex gap-4" justify="end">
      </NavbarContent>

      <NavbarContent className="sm:hidden" justify="end">
        <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
      </NavbarContent>

      <NavbarMenu className="bg-primary-light" style={{ paddingTop: '2.5rem', zIndex: '1001' }}>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/schwangerschaftsbetreuung" className="font-bold block w-full text-neutral-dark" size="lg">
              Schwangerschaftsbetreuung
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/geburtsvorbereitungskurs" className="font-bold block w-full text-neutral-dark" size="lg">
              Geburtsvorbereitungskurs
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/akupunktur" className="font-bold block w-full text-neutral-dark" size="lg">
              Akupunktur
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/taping" className="font-bold block w-full text-neutral-dark" size="lg">
              Taping
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/wochenbettbetreuung" className="font-bold block w-full text-neutral-dark" size="lg">
              Wochenbettbetreuung
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/beruf" className="font-bold block w-full text-neutral-dark" size="lg">
              Beruf
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/faq" className="font-bold block w-full text-neutral-dark" size="lg">
              FAQ
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/kontakt" className="font-bold block w-full text-neutral-dark" size="lg">
              Kontakt
            </Link>
          </Typography>
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
}
